







import Vue from "vue";
export default Vue.extend({
  props: {
    input: Object,
  },

  methods:{
      change(val:any){
        this.input.val = val ? 1 : 0
          this.$emit('change')
      }
  }
});
