












import Vue from "vue";
export default Vue.extend({
  props: {
    input: Object,
  },
  methods:{
      change(val:any){
        if(typeof this.input.change != 'undefined'){
          this.input.change(val)
        }
        this.input.val = val
        console.log(this.input)
        this.$emit('change' )
      }
  }
});
